import React from "react"
import interpColorFromPercent from "../../utils/quotas/interpColorFromPercent"

const lightgrey: RGB = [245, 245, 245]
const lightgreen: RGB = [161, 220, 66] //[144, 238, 144];
const red: RGB = [210, 70, 100]

const defaultColorMap = {
  min: { bg: lightgrey, text: [67, 72, 61] as RGB },
  max: { bg: lightgreen, text: [46, 84, 0] as RGB },
  warningMin: { bg: lightgrey, text: [67, 72, 61] as RGB },
  warningMax: { bg: red, text: [255, 255, 255] as RGB },
}

interface TextBg {
  text: RGB
  bg: RGB
}

interface ColorMap {
  min: TextBg
  max: TextBg
  warningMin: TextBg
  warningMax: TextBg
}

interface PercentPillProps {
  percentValue: number
  warning: boolean
  colorMap?: ColorMap
}

const PercentPill: React.FC<PercentPillProps> = ({
  percentValue,
  warning,
  colorMap = defaultColorMap,
}) => {
  const backgroundColor = interpColorFromPercent(
    warning ? colorMap.warningMax.bg : colorMap.max.bg,
    warning ? colorMap.warningMin.bg : colorMap.min.bg,
    percentValue
  )
  const textColor = warning
    ? percentValue > 70
      ? [255, 255, 255]
      : [62, 62, 62]
    : interpColorFromPercent(colorMap.max.text, colorMap.min.text, percentValue)
  return (
    <div
      className={`rounded-lg text-center w-16 text-sm select-none box-border relative ${
        warning ? `text-white` : `text-grey-700`
      }`}
      style={{
        transition: `border 0.5s ease-in-out`,
        borderRadius: 9,
        border: `2px solid rgb(${backgroundColor})`,
      }}
    >
      <div className="relative border border-white box-border rounded-lg overflow-hidden">
        <div
          className="absolute w-1/2 h-full left-0 top-0 z-0"
          style={{
            transition: `width 0.5s ease-in-out, background-color 0.5s`,
            borderRadius: `0 6px 6px 0`,
            width: percentValue < 100 ? `${percentValue}%` : `100%`,
            backgroundColor: `rgb(${backgroundColor})`,
          }}
        ></div>
        <span
          className="z-10 relative text-smm font-bold"
          style={{ color: `rgb(${textColor})` }}
        >
          {percentValue}%
        </span>
      </div>
    </div>
  )
}
export default PercentPill
