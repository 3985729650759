import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload
} from "@reduxjs/toolkit"
import React from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { Media } from "../../media"
import { RootState } from "../../state/createStore"
import { updatePortion } from "../../state/slices/foodsNutrientData"
import {
    closeUtilityModal, openUtilityModal
} from "../../state/slices/quickSearchUI"
import { updateMetrics } from "../../state/slices/quotas"
import { addSearchTag } from "../../state/slices/searchTags"
import CrossButton from "../CoreUI/CrossButton"
import FAB from "../CoreUI/FAB"
import Settings from "./Settings"

Modal.setAppElement("#___gatsby")

// const customStyles = {
//   content: {
//     top: "0",
//     left: "0",
//     right: "auto",
//     bottom: "auto",
//     width: 300,
//     // transform: "translate(0, 0)",
//     boxShadow: `0 2px 4px 0px rgba(0,0,0,0.15)`,
//     height: "100vh",
//     borderRadius: 0,
//     border: "none",
//     background: "white",
//     outline: "none",
//   },
//   overlay: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100vw",
//     height: "100vh",
//     backgroundColor: `rgba(0,0,0,0.2)`,
//     zIndex: 999,
//   },
// }

interface UtilityBarProps {
  metrics: Metrics
  addSearchTag: ActionCreatorWithPayload<string, string>
  updatePortion: ActionCreatorWithPayload<number, string>
  updateMetrics: ActionCreatorWithPayload<Metrics, string>
  nutrientNames: NutrientNames
  expandedFoodID: string
  portion: number
  tags: Array<string>
  utilityModalOpen: boolean
  openUtilityModal: ActionCreatorWithoutPayload<string>
  closeUtilityModal: ActionCreatorWithoutPayload<string>
}

const UtilityBar: React.FC<UtilityBarProps> = ({
  utilityModalOpen,
  openUtilityModal,
  closeUtilityModal,
}) => {
  return (
    <>
      <Media lessThan="md" className="test">
        <FAB
          visible={!utilityModalOpen}
          onClick={() => {
            openUtilityModal()
          }}
        />
        <Modal
          className={{
            base: "UtilityModal__Content",
            afterOpen: "UtilityModal__Content--after-open",
            beforeClose: "UtilityModal__Content--before-close",
          }}
          overlayClassName={{
            base: "UtilityModal__Overlay",
            afterOpen: "UtilityModal__Overlay--after-open",
            beforeClose: "UtilityModal__Overlay--before-close",
          }}
          isOpen={utilityModalOpen}
          onRequestClose={() => closeUtilityModal()}
          closeTimeoutMS={200}
        >
          <div className="pb-4 pt-2 px-6 ">
            <CrossButton onClick={() => closeUtilityModal()} />
            <Settings />
          </div>
        </Modal>
      </Media>
      <Media greaterThanOrEqual="md">
        <div
          className="flex relative pb-4 pt-2 bg-white border-r border-grey-150 sticky top-0 z-10 px-6 w-72 overflow-y-auto shadow"
          style={{ height: `calc(100vh - 56px)` }}
        >
          <Settings />
        </div>
      </Media>
    </>
  )
}

const mapState = (state: RootState) => {
  return {
    utilityModalOpen: state.quickSearchUI.utilityModalOpen,
    nutrientNames: state.foodsNutrientData.nutrientNames,
    expandedFoodID: state.quickSearchUI.expandedFoodID,
    portion: state.foodsNutrientData.portion,
    metrics: state.quotas.metrics,
    tags: state.searchTags,
  }
}

const mapDispatch = {
  addSearchTag,
  updatePortion,
  updateMetrics,
  openUtilityModal,
  closeUtilityModal,
}

export default connect(mapState, mapDispatch)(UtilityBar)
