import React, { useEffect, useRef, useState } from "react"
import { Transition } from "react-transition-group"
import SmallArrowDown from "../../images/svgs/buttons/smallArrowDown.svg"
import SmallArrowUp from "../../images/svgs/buttons/smallArrowUp.svg"
import useKeyPress from "../../utils/hooks/useKeyPress"
import usePrevious from "../../utils/hooks/usePrevious"
import "./input.css"

const validateNumVal = (val: string): boolean => {
  if (val === "") {
    return false
  }
  return true
}

interface InputProps {
  step?: number
  onSubmit: (val: number) => void
  label?: string
  sublabel?: string
  initialValue?: string | number
  widthFull?: boolean
  min?: number
  max?: number
}

const Input: React.FC<InputProps> = ({
  step = 1,
  onSubmit,
  label,
  sublabel,
  initialValue = "1",
  widthFull = false,
  min = null,
  max = null,
}) => {
  const inputRef = useRef(null)
  const enterPress = useKeyPress({ targetKey: "Enter", ref: inputRef })
  const [error, setError] = useState<string | null>(null)
  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState<string>(String(initialValue))
  const [preFocusValue, setPreFocusValue] = useState(String(initialValue))
  const cachedError = usePrevious(error)

  useEffect(() => {
    onSubmit(parseInt(value))
  }, [enterPress])

  useEffect(() => {
    if (focused) {
      setPreFocusValue(value)
    } else {
      if (!validateNumVal(value)) {
        setValue(preFocusValue)
      } else {
        onSubmit(parseInt(value))
      }
      setError(null)
    }
  }, [focused])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var regExp = /[^0-9]/g
    let val = e.target.value

    if (regExp.test(val)) {
      setError("Numbers Only")
      return
    }

    if (min !== null) {
      if (Number(val) < min) {
        setValue(String(min))
        setError(`Min: ${min}`)
        onSubmit(min)
        return
      }
    }

    if (max !== null) {
      if (Number(val) > max) {
        setValue(String(max))
        setError(`Max: ${max}`)
        onSubmit(max)
        return
      }
    }

    setError(null)
    setValue(val)
  }

  return (
    <div className={`relative flex-shrink-0 ${widthFull ? `w-full` : `w-32`}`}>
      {label ? (
        <label className="block mb-1 text-grey-700 font-bold">
          {label}{" "}
          <span className="text-grey-400 font-normal text-smm ml-1">
            {sublabel}
          </span>
        </label>
      ) : null}
      <div className="relative">
        <input
          ref={inputRef}
          className={`block w-full transition-bg duration-150 ease-in-out border rounded-md placeholder-grey-400 h-10 text-grey-700 outline-none text-center pr-5  text-base input-focus ${
            focused
              ? `bg-grey-100 border-gossip`
              : `bg-grey-100 border-grey-150`
          }`}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value}
          onChange={e => handleChange(e)}
        />
        <div className="number-input-btn-container">
          <button
            className="number-input-btn focus-visible"
            onClick={() => {
              setValue((parseInt(value) + step).toString())
              onSubmit(parseInt(value) + step)
            }}
          >
            <SmallArrowUp className="number-input-chevron" />
          </button>
          <button
            className="number-input-btn focus-visible"
            onClick={() => {
              setValue((parseInt(value) - step).toString())
              onSubmit(parseInt(value) - step)
            }}
          >
            <SmallArrowDown className="number-input-chevron" />
          </button>
        </div>
      </div>
      <Transition in={!!error} timeout={100} unmountOnExit>
        {state => (
          <div
            className="absolute custom-shadow bg-white mt-2 w-full rounded-md text-center p-1 text-xsm text-error-text border-error-border border z-10"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {error ? error?.toUpperCase() : cachedError?.toUpperCase()}
          </div>
        )}
      </Transition>
    </div>
  )
}
export default Input

const defaultStyle = {
  transition: `transform 100ms, opacity 100ms ease`,
  opacity: 1,
}

const transitionStyles = {
  entering: { transform: "translateY(-10px) scale(0.9)", opacity: 0 },
  entered: { transform: "translateY(0px) scale(1)", opacity: 1 },
  exiting: { transform: "translateY(-10px) scale(0.9)", opacity: 0 },
  exited: { transform: "translateY(-10px) scale(0.9)", opacity: 0 },
}
