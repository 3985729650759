import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import React from "react"
import { connect } from "react-redux"
import Expand from "../../../images/svgs/buttons/expand.svg"
import { RootState } from "../../../state/createStore"
import { openExpandedFoodInfo } from "../../../state/slices/quickSearchUI"
import FoodTypePill from "../../CoreUI/FoodTypePill"

interface FoodSearchTileProps {
  foodName: string
  animationDelay: number
  openExpandedFoodInfo: ActionCreatorWithPayload<string, string>
  foodTypes: { [key: string]: string }
}

const FoodSearchTile: React.FC<FoodSearchTileProps> = ({
  foodName,
  openExpandedFoodInfo,
  animationDelay,
  foodTypes,
}) => {
  return (
    <div
      className="w-full h-16 flex items-center bg-white rounded-lg shadow border-grey-150 border px-4 fadeIn opacity-0 mb-2"
      style={{
        animationDelay: `${animationDelay}s`,
      }}
    >
      <h2 className="text-lg text-grey-700 font-bold mr-3">{foodName}</h2>
      <FoodTypePill foodType={foodTypes[foodName]} />
      <div className="flex-1"></div>
      <button
        onClick={() => openExpandedFoodInfo(foodName)}
        className="p-2 border hover:bg-grey-100 border-transparent rounded-md justify-self-end transition-all duration-300 ease-in-out focus-visible group"
      >
        <Expand className="transition-fill duration-300 ease-in-out fill-current group-hover:text-grey-600 text-grey-300" />
      </button>
    </div>
  )
}

const mapState = (state: RootState) => {
  return {
    foodTypes: state.foodsNutrientData.foodTypes,
  }
}

const mapDispatch = {
  openExpandedFoodInfo,
}

export default connect(mapState, mapDispatch)(FoodSearchTile)
