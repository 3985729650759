import React from "react"
import { connect } from "react-redux"
import MetricsModal from "../components/CoreUI/MetricsModal"
import NavBar from "../components/CoreUI/NavBar"
import ContentsController from "../components/QuickSearch/ContentsController"
import UtilityBar from "../components/QuickSearch/UtilityBar"
import SEO from "../components/seo"
import AboutModal from "../components/shared/AboutModal"
import { RootState } from "../state/createStore"
import { closeMetricsModal } from "../state/slices/quickSearchUI"
import { updateMetrics } from "../state/slices/quotas"

interface QuickSearchPageProps {
  metricsModalOpen: boolean
  closeMetricsModal: typeof closeMetricsModal
  updateMetrics: typeof updateMetrics
  metrics: Metrics
}

const QuickSearchPage: React.FC<QuickSearchPageProps> = ({
  metricsModalOpen,
  closeMetricsModal,
  updateMetrics,
  metrics,
}) => {
  return (
    <div className="h-screen max-h-screen flex flex-col relative bg-kidnapper-light">
      <SEO
        title="Quick Search"
        description="Find foods with the highest values of selected nutrients, need to
              find out where you can get more X, Y or Z? This can help."
      />
      <AboutModal />
      <MetricsModal
        open={metricsModalOpen}
        closeModal={closeMetricsModal}
        updateMetrics={updateMetrics}
        initialMetrics={metrics}
      />
      <NavBar pageName="Quick Search" to="/quick-search" />
      <div className="flex flex-1">
        <UtilityBar />
        <ContentsController />
      </div>
    </div>
  )
}

const mapDispatch = {
  closeMetricsModal,
  updateMetrics,
}

const mapState = (state: RootState) => {
  return {
    metricsModalOpen: state.quickSearchUI.metricsModalOpen,
    metrics: state.quotas.metrics,
  }
}

export default connect(mapState, mapDispatch)(QuickSearchPage)
