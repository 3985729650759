const changeSingleFoodName = (
  foodNameOrder: Array<string>,
  index: number,
  newFoodName: string
) => {
  return foodNameOrder.map((val, idx) => {
    if (idx === index) {
      return newFoodName
    }
    return val
  })
}
export default changeSingleFoodName
