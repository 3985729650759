import { useEffect, useState } from "react"
import useMounted from "../hooks/useMounted"
import usePrevious from "../hooks/usePrevious"

const useBalanceTiles = (
  verticalRows: number,
  flippedTiles: Array<boolean>
): [number, Array<number>] => {
  const initialTiles = 6

  const [maxHorizontalTiles, setMaxHorizontalTiles] = useState(3)
  const [maxShownTiles, setMaxShownTiles] = useState(6)
  const [animationDelays, setAnimationDelays] = useState(
    generateAnimationDelays(0.1, initialTiles)
  )

  const mounted = useMounted()
  const prevVerticalRows = usePrevious(verticalRows)

  useEffect(() => {
    const newAnimationDelays = generateAnimationDelays(0.1, maxShownTiles, [])
    setAnimationDelays(newAnimationDelays)
  }, [flippedTiles])

  useEffect(() => {
    let debounceTimer: null | NodeJS.Timeout = null

    const debounceResizeEvent = () => {
      if (debounceTimer !== null) {
        clearTimeout(debounceTimer)
      }
      debounceTimer = setTimeout(updateMaxTiles, 50)
    }

    const updateMaxTiles = () => {
      const currentMaxHorizontalTiles = calcMaxHorizontalTiles()
      //Process if there is a change in viewable max horizontal tiles or total tile rows
      if (
        currentMaxHorizontalTiles !== maxHorizontalTiles ||
        prevVerticalRows !== verticalRows
      ) {
        //Work out new total tiles by horizontal tiles * rows
        let newMaxTiles = currentMaxHorizontalTiles * verticalRows
        //Clip minimum tiles at 6
        if (newMaxTiles < 6) newMaxTiles = 6

        //Call state changes
        //If totalDiff -ve pop some entries, if +ve push
        const totalTileDiff = newMaxTiles - maxShownTiles
        if (totalTileDiff >= 0) {
          //Reset previous animation delays to 0s
          const oldAnimationDelays = mounted
            ? Array(maxShownTiles).fill(0)
            : animationDelays
          const newAnimationDelays = generateAnimationDelays(
            0.1,
            totalTileDiff,
            oldAnimationDelays
          )
          setAnimationDelays(newAnimationDelays)
        } else {
          const emptyAnimationDelays = Array(newMaxTiles).fill(0)
          setAnimationDelays(emptyAnimationDelays)
        }
        setMaxShownTiles(newMaxTiles)
        setMaxHorizontalTiles(currentMaxHorizontalTiles)
      }
    }
    updateMaxTiles()
    window.addEventListener("resize", debounceResizeEvent)
    return function cleanup() {
      window.removeEventListener("resize", debounceResizeEvent)
    }
  }, [maxShownTiles, verticalRows, mounted])

  return [maxShownTiles, animationDelays]
}
export default useBalanceTiles

const generateAnimationDelays = (
  delta: number,
  total: number,
  previousDelays: Array<number> = []
) => {
  let delays: Array<number> = previousDelays || []
  //Need to check previous delay to add on from it
  let startingDelay = delays.length > 0 ? delays[delays.length - 1] : 0
  for (let i = 0; i < total; i++) {
    delays.push(startingDelay + i * delta)
  }
  return delays
}

const calcMaxHorizontalTiles = () => {
  if (typeof window !== `undefined`) {
    let width =
      window.innerWidth > window.screen.width
        ? window.screen.width
        : window.innerWidth
    width -= 288 // Minus sidebar width
    //5% margins
    width = width - 40 // Left/right margins
    //275px width + 20px surrounding margin for a tile
    let horizontalCount = Math.floor(width / 310)
    return horizontalCount < 6 ? horizontalCount : 6
  }
  return 6
}
