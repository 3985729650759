import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { RootState } from "../../state/createStore"
import { closeExpandedFoodInfo } from "../../state/slices/quickSearchUI"
import useMounted from "../../utils/hooks/useMounted"
import ContentTopBar from "./ContentTopBar"
import ExpandedFoodContainer from "./ExpandedFood/ExpandedFoodContainer"
import FoodSearchContainer from "./FoodSearch/FoodSearchContainer"
import Footer from "./Footer"
import TileContainer from "./SmallFoodTile/TileContainer"

interface ContentsControllerProps {
  searchMode: "food" | "nutrient"
  closeExpandedFoodInfo: ActionCreatorWithoutPayload<string>
  expandedFoodID: string | null
}

export type Modes = "nutrient" | "expandedFood" | "food"

const ContentsController: React.FC<ContentsControllerProps> = ({
  searchMode,
  expandedFoodID,
}) => {
  const [mode, setMode] = useState<Modes>(searchMode)
  const mounted = useMounted()

  useEffect(() => {
    if (expandedFoodID && expandedFoodID.length && mounted) {
      setMode("expandedFood")
    }
  }, [expandedFoodID])

  useEffect(() => {
    if (mode !== "expandedFood") {
      setMode(searchMode)
    }
  }, [searchMode])

  const handleCloseExpandedFood = () => {
    // @TODO: Change this to use searchMode instead
    setMode(searchMode)
  }

  return (
    <>
      {mounted ? (
        <div
          className="flex-1 flex flex-col z-20"
          style={{ height: "calc(100vh - 56px" }}
        >
          <ContentTopBar
            expandedFoodID={false}
            contentMode={mode}
            closeExpandedFoodInfo={handleCloseExpandedFood}
          />
          <SwitchTransition>
            <CSSTransition
              key={mode}
              addEndListener={(node: any, done: any) => {
                const finishedTransition = (e: TransitionEvent) => {
                  if (e.target === node) {
                    done()
                  }
                }
                node.addEventListener(
                  "transitionend",
                  finishedTransition,
                  false
                )
              }}
              classNames="fade"
            >
              <div className="flex-1 flex flex-col overflow-y-scroll">
                {mode === "nutrient" ? <TileContainer /> : null}
                {mode === "expandedFood" ? <ExpandedFoodContainer /> : null}
                {mode === "food" ? <FoodSearchContainer /> : null}
                <Footer />
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      ) : null}
    </>
  )
}

const mapDispatch = {
  closeExpandedFoodInfo,
}

const mapState = (state: RootState) => {
  return {
    expandedFoodID: state.quickSearchUI.expandedFoodID,
    searchMode: state.quickSearchUI.searchMode,
  }
}

export default connect(mapState, mapDispatch)(ContentsController)
