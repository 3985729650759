import React, { useState } from "react"

interface ToggleProps {
  offLabel: string
  onLabel: string
  onToggle: () => void
  initialState: boolean
}

const Toggle: React.FC<ToggleProps> = ({
  offLabel,
  onLabel,
  onToggle,
  initialState,
}) => {
  const [toggled, setToggled] = useState(initialState)

  const handleToggle = () => {
    onToggle()
    setToggled(!toggled)
  }

  return (
    <div className="flex items-center mb-2 font-bold">
      <label
        className={`mr-2 transition-all duration-300 ease-in-out ${
          !toggled ? `text-grey-700` : `text-grey-400`
        }`}
      >
        {offLabel}
      </label>
      <button
        className={`relative rounded-full border border-grey-150 h-6 w-12 flex items-center px-1 bg-grey-100 focus-visible`}
        onClick={() => handleToggle()}
      >
        <div
          className={`h-4 w-4 rounded-full bg-gradient-to-b from-sulu-light to-sulu-dark absolute transition-all duration-300 ease-in-out ${
            toggled ? `toggle-label-on` : `toggle-label-off`
          }`}
        />
      </button>
      <label
        className={`ml-2 transition-all duration-300 ease-in-out ${
          toggled ? `text-grey-700` : `text-grey-400`
        }`}
      >
        {onLabel}
      </label>
    </div>
  )
}
export default Toggle
