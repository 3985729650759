import * as _ from "lodash"
import { useEffect, useState } from "react"
import { TileContainerProps } from "../../components/QuickSearch/SmallFoodTile/TileContainer"
import usePrevious from "../hooks/usePrevious"
import calcFoodOrder from "./calcFoodOrder"
import genRandomFoodOrder from "./genRandomFoodOrder"
import { flipAllTiles } from "./tileFlips"

//Hook for handling tile flipping based on change in tag/rdi state
const useReactiveFoodOrder = (
  props: TileContainerProps,
  flippedTiles: Array<boolean>,
  setFlippedTiles: React.Dispatch<React.SetStateAction<any[]>>,
  onFoodOrderChange: (foodOrder: Array<string>) => void
): [Array<string>, React.Dispatch<React.SetStateAction<any[]>>] => {
  const [foodNamesOrder, setFoodNamesOrder] = useState(
    props.searchTags.length
      ? calcFoodOrder(props)
      : genRandomFoodOrder(props.foodNames)
  )

  const prevTag = usePrevious(props.searchTags)
  const prevFoodData = usePrevious(props.foodsNutrientData)
  const prevPersonalQuotas = usePrevious(props.userQuotas)

  // Determine whether props have changed, if so flip all the tiles with new food order
  useEffect(() => {
    // Do not flip before usePrevious hooks have initialised (1 render cycle)
    if (
      prevTag !== undefined &&
      prevPersonalQuotas !== undefined &&
      prevFoodData !== undefined
    ) {
      // Deep equality check objects/arrays with previously stored
      if (
        !_.isEqual(props.searchTags, prevTag) ||
        !_.isEqual(props.userQuotas, prevPersonalQuotas) ||
        !_.isEqual(props.foodsNutrientData, prevFoodData)
      ) {
        let newFoodOrder = []
        if (props.searchTags.length) {
          newFoodOrder = calcFoodOrder(props)
          setFoodNamesOrder(newFoodOrder)
        } else {
          newFoodOrder = genRandomFoodOrder(props.foodNames)
          setFoodNamesOrder(newFoodOrder)
        }
        onFoodOrderChange(newFoodOrder)
        setFlippedTiles(flipAllTiles(flippedTiles))
      }
    }
  }, [props.searchTags, props.userQuotas, props.foodsNutrientData])

  return [foodNamesOrder, setFoodNamesOrder]
}
export default useReactiveFoodOrder
