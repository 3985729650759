const filterNutrientNames = (
  nutrientNames: Array<string>,
  toBeRemoved: Array<string>
) => {
  const filteredNutrientNames: Array<string> = nutrientNames.filter(val => {
    for (let selection of toBeRemoved) {
      if (selection === val) {
        return false
      }
    }
    return true
  })
  return filteredNutrientNames
}
export default filterNutrientNames
