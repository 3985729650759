import React, { useEffect, useRef, useState } from "react"
import SelectChevron from "../../images/svgs/icons/selectChevron.svg"
import useClickOutside from "../../utils/hooks/useClickOutside"
import useKeyPress from "../../utils/hooks/useKeyPress"

interface SelectorInputProps {
  label?: string
  sublabel?: string
  options: Array<{
    value: string
    label: string
  }>
  initialValue: string
  onSubmit: (val: string) => void
  widthFull?: boolean
}

const SelectorInput: React.FC<SelectorInputProps> = props => {
  const ref = useRef(null)
  const selectorRef = useRef(null)
  const escPress = useKeyPress({ targetKey: "Escape", ref: ref })
  const enterPress = useKeyPress({ targetKey: "Enter", ref: selectorRef })
  const downPress = useKeyPress({
    targetKey: "ArrowDown",
    ref: ref,
    preventDefault: true,
  })
  const upPress = useKeyPress({
    targetKey: "ArrowUp",
    ref: ref,
    preventDefault: true,
  })
  const [focused, setFocused] = useState(false)
  const [cursor, setCursor] = useState(0)
  const [value, setValue] = useState(props.initialValue)
  const [open, setOpen] = useState(false)
  useClickOutside(ref, () => setOpen(false))

  const handleSelect = (newValue: string) => {
    setValue(newValue)
    props.onSubmit(newValue)
    setOpen(false)
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (props.options.length && upPress) {
      setCursor(prevState =>
        prevState > 0 ? prevState - 1 : props.options.length - 1
      )
    }
  }, [upPress])

  useEffect(() => {
    if (props.options.length && downPress) {
      setCursor(prevState =>
        prevState < props.options.length - 1 ? prevState + 1 : 0
      )
    }
  }, [downPress])

  useEffect(() => {
    setOpen(false)
  }, [escPress])

  useEffect(() => {
    if (!open && enterPress) {
      setOpen(true)
    } else if (enterPress) {
      setValue(props.options[cursor].value)
      setOpen(false)
    }
  }, [enterPress])

  const selectedOption = props.options.filter(
    option => option.value === value
  )[0]

  const showOptions = focused && open

  return (
    <div
      className={`relative ${props.widthFull ? `w-full` : `w-32`}`}
      ref={ref}
    >
      {props.label ? (
        <label className="block mb-1 text-grey-700 font-bold">
          {props.label}{" "}
          <span className="text-grey-400 font-normal text-smm ml-1">
            {props.sublabel}
          </span>
        </label>
      ) : null}
      <div
        className={`transition-bg duration-150 ease-in-out input-focus border group rounded-md placeholder-grey-400 h-10 text-center text-grey-700 outline-none text-base flex items-center justify-center ${
          focused ? `bg-grey-100 border-gossip` : `bg-grey-100 border-grey-150`
        }`}
        tabIndex={0}
        onClick={() => toggleOpen()}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false)
        }}
        ref={selectorRef}
      >
        <div className="select-none flex-1">{selectedOption.label}</div>
        <SelectChevron
          className={`mr-3 fill-current text-grey-300 transition duration-200 transform group-hover:text-grey-400 ${
            showOptions ? `rotate-180` : `rotate-0`
          }`}
        />
      </div>
      {/* {focused && open ? (
        // <FocusTrap> */}
      <div
        className={`absolute bg-grey-100 w-full custom-shadow mt-2 z-50 rounded-md border border-grey-150 overflow-hidden ${
          showOptions ? `block` : `hidden`
        }`}
        role="dialog"
      >
        <ul role="listbox">
          {props.options.map((option, idx) => (
            <Option
              key={option.value}
              value={option.value}
              label={option.label}
              onClick={handleSelect}
              selectedValue={selectedOption.value}
              hovered={cursor === idx}
              onHover={() => setCursor(idx)}
            />
          ))}
        </ul>
      </div>
      {/* ) : // </FocusTrap>
      null} */}
    </div>
  )
}
export default SelectorInput

interface OptionProps {
  value: string
  label: string
  onClick: (value: string) => void
  selectedValue: string
  hovered: boolean
  onHover: () => void
}

const Option: React.FC<OptionProps> = ({
  value,
  label,
  onClick,
  hovered,
  onHover,
}) => {
  return (
    <li
      onMouseDown={() => onClick(value)}
      onMouseOver={() => onHover()}
      onMouseLeave={() => {}}
      className={`select-none cursor-pointer px-4 py-2 ${
        hovered ? `bg-feta-bg text-feta-rich` : `bg-grey-100`
      }`}
    >
      {label}
    </li>
  )
}
