import { useState, useEffect } from "react"

const useWindowIdle = (searchTags: Array<string>, tilesHovered: boolean) => {
  const [windowIdle, setWindowIdle] = useState(true)

  useEffect(() => {
    const handleIdle = () => {
      if (
        (document.hidden || searchTags.length || tilesHovered) &&
        windowIdle
      ) {
        setWindowIdle(false)
      } else if (
        searchTags.length === 0 &&
        !windowIdle &&
        !document.hidden &&
        !tilesHovered
      ) {
        setWindowIdle(true)
      }
    }
    handleIdle()
    document.addEventListener("visibilitychange", handleIdle)
    return function cleanup() {
      document.removeEventListener("visibilitychange", handleIdle)
    }
  }, [windowIdle, searchTags, tilesHovered])

  return windowIdle
}
export default useWindowIdle
