import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload
} from "@reduxjs/toolkit"
import React, { useEffect, useReducer, useRef, useState } from "react"
import Modal from "react-modal"
import useClickOutsideModal from "../../utils/hooks/useClickOutsideModal"
import NumericInput from "./NumericInput"
import SelectorInput from "./SelectorInput"

Modal.setAppElement("#___gatsby")

const customStyles = {
  content: {
    top: "0px",
    right: "auto",
    left: "0px",
    bottom: "auto",
    marginLeft: "50%",
    boxShadow: `0 0 2px 0 rgba(0, 0, 0, 0.12), 0 0 9px 4px rgba(0, 0, 0, 0.06)`,
    position: "absolute",
    transform: "translate(0px, 0px)",
    overflow: "visible",
  },
  overlay: {
    backgroundColor: "transparent",
    width: "1px",
    height: "1px",
    position: "fixed",
    zIndex: 50,
  },
}

const updateStyles = (right: number, top: number) => {
  customStyles.content.transform = `translate(calc(${right}px - 100%), ${
    top + 5
  }px)`
}

type Sex = "male" | "female"

type Action =
  | { type: "height"; height: number }
  | { type: "weight"; weight: number }
  | { type: "age"; age: number }
  | { type: "sex"; sex: Sex }

function metricsReducer(oldState: Metrics, action: Action) {
  const state = { ...oldState }
  switch (action.type) {
    case "height":
      state.height = action.height
      break
    case "weight":
      state.weight = action.weight
      break
    case "age":
      state.age = action.age
      break
    case "sex":
      state.sex = action.sex
      break
    default:
      throw new Error()
  }
  return state
}

interface MetricsModalProps {
  open: boolean
  closeModal: ActionCreatorWithoutPayload<string>
  updateMetrics: ActionCreatorWithPayload<Metrics, string>
  initialMetrics: Metrics
}

const MetricsModal: React.FC<MetricsModalProps> = ({
  open,
  closeModal,
  initialMetrics,
  updateMetrics,
}) => {
  if (typeof window !== `undefined`) {
    const ref = useRef(null)
    const buttonElement = document.getElementById("metric-options-button")
    const [right, top] = useModalReactiveResize(buttonElement)
    const [metrics, dispatch] = useReducer(metricsReducer, initialMetrics)

    useClickOutsideModal(closeModal, ref, buttonElement)
    updateStyles(right, top)

    const handleMetricSubmit = () => {
      updateMetrics(metrics)
      closeModal()
    }

    return (
      <Modal
        isOpen={open}
        onRequestClose={() => closeModal()}
        // style={customStyles}
        contentLabel="Example Modal"
        ref={ref}
      >
        <div className="w-48 relative">
          <NumericInput
            onSubmit={height => dispatch({ type: "height", height })}
            label="Height"
            sublabel="(cm)"
            initialValue={metrics?.height}
            widthFull={true}
          />
          <div className="h-3" />
          <NumericInput
            onSubmit={weight => dispatch({ type: "weight", weight })}
            label="Weight"
            sublabel="(kg)"
            initialValue={metrics.weight}
            widthFull={true}
          />
          <div className="h-3" />
          <NumericInput
            onSubmit={age => dispatch({ type: "age", age })}
            label="Age"
            sublabel="(years)"
            initialValue={metrics.age}
            widthFull={true}
          />
          <div className="h-3" />
          <SelectorInput
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
            ]}
            initialValue="male"
            onSubmit={sex => dispatch({ type: "sex", sex: sex as Sex })}
            label="Sex"
            sublabel="(m/f)"
            widthFull={true}
          />
          <div className="h-5" />
          <button
            className="w-full bg-conifer-light text-center rounded-md p-1 focus-visible hover:bg-conifer-rich"
            onClick={() => handleMetricSubmit()}
          >
            SUBMIT
          </button>
        </div>
      </Modal>
    )
  }
  return null
}
export default MetricsModal

const useModalReactiveResize = (
  buttonElement: HTMLElement | null
): [right: number, top: number] => {
  const rect = buttonElement?.getBoundingClientRect()
  const [right, setRight] = useState(rect?.right ? rect?.right : 0)
  const [top, setTop] = useState(
    rect?.y && rect?.height ? rect?.y + rect?.height : 0
  )

  useEffect(() => {
    const handleResize = () => {
      const rect = buttonElement?.getBoundingClientRect()
      setRight(rect?.right ? rect?.right : 0)
      setTop(rect?.y && rect?.height ? rect?.y + rect?.height : 0)
      customStyles.content.transform = `translate(calc(${right}px - 100%), ${
        top + 5
      }px)`
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  })

  return [right, top]
}
