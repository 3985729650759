import React from "react"
import { connect } from "react-redux"
import { RootState } from "../../../state/createStore"
import Tooltip from "../../CoreUI/Tooltip"
import { PercentPill } from "../../PercentPill"

interface TileTableProps extends TileTableOwnProps {
  nutrientUnits: {
    [key: string]: string
  }
  portionSize: number
  userQuotas: UserPersonalQuotas
}

const TileDataTable: React.FC<TileTableProps> = props => {
  return (
    <>
      {props.tableTitle ? (
        <h4 className="text-sm text-grey-400 mb-1 font-bold">
          {props.tableTitle}
        </h4>
      ) : null}
      <table className="w-full relative mb-2">
        <tbody className="text-grey-700 relative">
          {props.selectedNutrients.map((nutrientName, idx) => (
            <tr key={idx}>
              <td className="w-24 border-r border-grey-125">{nutrientName}</td>

              <td className="px-3 py-1 border-r border-grey-125 select-none">
                <Tooltip
                  content={`${props.foodNutrientData[nutrientName]}${props.nutrientUnits[nutrientName]} per ${props.portionSize}g`}
                >
                  {props.foodNutrientData[nutrientName]}
                  <span className="text-sm ml-1">
                    {props.nutrientUnits[nutrientName]}
                  </span>
                </Tooltip>
              </td>

              <td className="pl-2 relative h-8">
                <Tooltip
                  content={
                    props.userQuotas[nutrientName].type === "RDA_UL"
                    ? `${props.foodNutrientData[nutrientName]}${props.nutrientUnits[nutrientName]} of ${(props.userQuotas[nutrientName] as RDAULQuota).rda}${props.nutrientUnits[nutrientName]} / day`
                      : ``
                  }
                >
                  {props.foodQuotaObjects[nutrientName]?.displayValue !==
                  undefined ? (
                    <PercentPill
                      percentValue={
                        props.foodQuotaObjects[nutrientName]
                          ?.displayValue as number
                      }
                      warning={
                        props.foodQuotaObjects[nutrientName]?.warning as boolean
                      }
                    />
                  ) : null}
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

interface TileTableOwnProps {
  selectedNutrients: Array<string>
  foodNutrientData: FoodsNutrientData["food"]
  foodQuotaObjects: QuotaObjects
  tableTitle?: string
}

const mapState = (state: RootState, ownProps: TileTableOwnProps) => {
  const { foodsNutrientData, quotas } = state
  return {
    selectedNutrients: ownProps.selectedNutrients,
    foodNutrientData: ownProps.foodNutrientData,
    nutrientUnits: foodsNutrientData.nutrientUnits,
    portionSize: foodsNutrientData.portion,
    userQuotas: quotas.userQuotas,
    tableTitle: ownProps.tableTitle,
  }
}

export default connect(mapState)(TileDataTable)
