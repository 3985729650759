import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from "@reduxjs/toolkit"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Minus from "../../../images/svgs/buttons/minus.svg"
import Plus from "../../../images/svgs/buttons/plus.svg"
import { Media } from "../../../media"
import { RootState } from "../../../state/createStore"
import {
  cacheFoodOrder,
  closeExpandedFoodInfo,
} from "../../../state/slices/quickSearchUI"
import changeSingleFoodName from "../../../utils/foodTiles/changeSingleFoodName"
import { flipSingleTile } from "../../../utils/foodTiles/tileFlips"
import useBalanceTiles from "../../../utils/foodTiles/useBalanceTiles"
import useReactiveFoodOrder from "../../../utils/foodTiles/useReactiveFoodOrder"
import useInterval from "../../../utils/hooks/useInterval"
import useWindowIdle from "../../../utils/hooks/useWindowIdle"
import Tile from "./Tile"
import "./tiles.css"

export interface TileContainerProps {
  searchTags: NutrientNames
  userQuotas: UserPersonalQuotas
  foodNames: FoodNames
  foodsNutrientData: FoodsNutrientData
  nutrientDataRanges: DataRanges
  nutrientDataMeans: DataMeans
  closeExpandedFoodInfo: ActionCreatorWithoutPayload<string>
  cacheFoodOrder: ActionCreatorWithPayload<Array<string>>
}

const TileContainer: React.FC<TileContainerProps> = props => {
  const [verticalRows, setVerticalRows] = useState(2)
  const [flippedTiles, setFlippedTiles] = useState(Array(25).fill(false))
  const [tilesHovered, setTilesHovered] = useState(false)
  const [foodNamesOrder, setFoodNamesOrder] = useReactiveFoodOrder(
    props,
    flippedTiles,
    setFlippedTiles,
    props.cacheFoodOrder
  )
  const windowIdle = useWindowIdle(props.searchTags, tilesHovered)
  const [maxShownTiles, animationDelays] = useBalanceTiles(
    verticalRows,
    flippedTiles
  )

  // Reset expandedFoodID on mount
  useEffect(() => {
    props.closeExpandedFoodInfo()
  }, [])

  //Hook for flipping a random tile every interval during idle
  useInterval(
    () => {
      const randIndex = Math.floor(Math.random() * maxShownTiles)
      const randFoodName =
        props.foodNames[Math.floor(Math.random() * props.foodNames.length)]
      setFoodNamesOrder(
        changeSingleFoodName(foodNamesOrder, randIndex, randFoodName)
      )
      setFlippedTiles(flipSingleTile(flippedTiles, randIndex))
    },
    windowIdle ? 3500 : null
  )

  const addRow = () => {
    if (verticalRows < 3) {
      setVerticalRows(verticalRows + 1)
    }
  }
  const removeRow = () => {
    if (verticalRows > 2) {
      setVerticalRows(verticalRows - 1)
    }
  }

  const canAddRow = verticalRows < 3

  const foodNamesClipped = [...foodNamesOrder].splice(0, maxShownTiles)

  return (
    <div
      className="mb-6 flex-1"
      onTransitionEnd={e => {
        e.stopPropagation()
      }}
    >
      <div
        className="flex flex-wrap tile-container flex-1 relative z-0 justify-center"
        onMouseEnter={() => setTilesHovered(true)}
        onMouseLeave={() => setTilesHovered(false)}
      >
        {foodNamesClipped.map((foodName, idx) => (
          <Tile
            index={idx}
            foodName={foodName}
            flipped={flippedTiles[idx]}
            key={idx}
            animationDelay={animationDelays[idx]}
          />
        ))}
      </div>
      <Media greaterThanOrEqual="xl">
        <button
          className="rounded-full shadow bg-white h-8 w-8 flex items-center justify-center mx-auto focus-visible group"
          onClick={() => (canAddRow ? addRow() : removeRow())}
        >
          {canAddRow ? (
            <Plus className="text-grey-400 fill-current group-hover:text-grey-600" />
          ) : (
            <Minus className="text-grey-400 fill-current group-hover:text-grey-600" />
          )}
        </button>
      </Media>
    </div>
  )
}

const mapDispatch = {
  closeExpandedFoodInfo,
  cacheFoodOrder,
}

const mapState = (state: RootState) => {
  const { quotas, foodsNutrientData, searchTags } = state
  return {
    searchTags: searchTags,
    userQuotas: quotas.userQuotas,
    foodNames: foodsNutrientData.foodNames,
    foodsNutrientData: foodsNutrientData.portionScaledFoodsNutrientData,
    nutrientDataRanges: foodsNutrientData.ranges,
    nutrientDataMeans: foodsNutrientData.means,
  }
}

export default connect(mapState, mapDispatch)(TileContainer)
