import React from "react"
import { connect } from "react-redux"
import Expand from "../../../images/svgs/buttons/expand.svg"
import {
    openExpandedFoodInfo
} from "../../../state/slices/quickSearchUI"
import calcTopNutrients from "../../../utils/foodTiles/calcTopNutrients"
import TileDataTable from "./TileDataTable"

export interface SideData {
  rank: number
  foodName: string
  foodNutrientData: FoodsNutrientData["Food"]
  tags: Array<string>
  foodQuotaObjects: QuotaObjects
  foodType: string
}

interface TileSideProps {
  cachedSideData: SideData
  rotation: number
  openExpandedFoodInfo: typeof openExpandedFoodInfo
  tabIndex: number
}

const TileSide: React.FC<TileSideProps> = ({
  tabIndex,
  rotation,
  cachedSideData,
  openExpandedFoodInfo,
}) => {
  const topNutrientsToShow = cachedSideData.tags.length
    ? 3 - cachedSideData.tags.length
    : 4
  const topNutrients = calcTopNutrients(
    cachedSideData.foodQuotaObjects,
    cachedSideData.tags
  ).slice(0, topNutrientsToShow)

  return (
    <div
      className="w-full h-full bg-white border-kelp-border border shadow-tight rounded-4 absolute tile-side"
      style={{ transform: `rotateY(${rotation.toString()}deg)` }}
    >
      <div className="m-auto px-4 pt-2">
        <div className="my-2 flex items-center">
          {cachedSideData.tags.length > 0 ? (
            <span
              className="font-bold text-md mr-2 text-grey-700"
            >
              {cachedSideData.rank}.
            </span>
          ) : null}
          <h3 className="text-lg text-grey-700 font-bold">
            {cachedSideData.foodName}
          </h3>

          <div className="flex-1"></div>
          <button
            tabIndex={tabIndex}
            onClick={() => openExpandedFoodInfo(cachedSideData.foodName)}
            className="p-2 border hover:bg-grey-100 border-transparent rounded-md justify-self-end transition-all duration-300 ease-in-out focus-visible group"
          >
            <Expand className="transition-fill duration-300 ease-in-out fill-current group-hover:text-grey-600 text-grey-300" />
          </button>
        </div>
        {cachedSideData.tags.length > 0 ? (
          <TileDataTable
            tableTitle={"Searched"}
            selectedNutrients={cachedSideData.tags}
            foodNutrientData={cachedSideData.foodNutrientData}
            foodQuotaObjects={cachedSideData.foodQuotaObjects}
          />
        ) : null}
        {topNutrientsToShow > 0 ? (
          <TileDataTable
            tableTitle={"Highest"}
            selectedNutrients={topNutrients}
            foodNutrientData={cachedSideData.foodNutrientData}
            foodQuotaObjects={cachedSideData.foodQuotaObjects}
          />
        ) : null}
      </div>
    </div>
  )
}

const mapDispatch = {
  openExpandedFoodInfo,
}

export default connect(null, mapDispatch)(TileSide)
