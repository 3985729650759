import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
    CSSTransition,
    SwitchTransition
} from "react-transition-group"
import EmptyBowl from "../../../images/emptyBowl.png"
import { RootState } from "../../../state/createStore"
import useMounted from "../../../utils/hooks/useMounted"
import FoodSearchTile from "./FoodSearchTile"

interface FoodSearchContainerProps {
  foodNames: Array<string>
  searchValue: string
}

const matchSearchTerm = (list: Array<string>, searchTerm: string) => {
  if (searchTerm === "") {
    return list
  }
  return list.filter(val =>
    val.toLowerCase().includes(searchTerm.toLowerCase())
  )
}

const FoodSearchContainer: React.FC<FoodSearchContainerProps> = ({
  foodNames,
  searchValue,
}) => {
  const [searchFlip, setSearchFlip] = useState(false)
  const [cachedFoodNames, setCachedFoodNames] = useState<Array<string>>(
    matchSearchTerm(foodNames, searchValue)
  )
  const mounted = useMounted()

  useEffect(() => {
    if (mounted) {
      setSearchFlip(!searchFlip)
    }
  }, [searchValue])

  return (
    <div
      className="mx-auto py-4 flex-1 px-4"
      style={{ width: "100%", maxWidth: "800px" }}
    >
      <SwitchTransition>
        <CSSTransition
          key={searchFlip ? "f" : "t"}
          addEndListener={(node: any, done: any) => {
            const finishedTransition = (e: TransitionEvent) => {
              if (e.target === node) {
                setCachedFoodNames(matchSearchTerm(foodNames, searchValue))
                setSearchFlip(!setSearchFlip)
                done()
              }
            }
            node.addEventListener("transitionend", finishedTransition, false)
          }}
          classNames="fade"
          // timeout={200}
          // style={{ transitionDuration: `0.2s` }}
        >
          <div className="relative w-full">
            {cachedFoodNames.map((foodName, idx) => (
              <FoodSearchTile
                key={`food-search-tile-${foodName}-${idx}`}
                foodName={foodName}
                animationDelay={Math.min(idx * 0.08, 2)}
              />
            ))}
            {cachedFoodNames.length <= 0 && searchValue !== "" ? (
              <div className="flex items-center flex-col bg-white rounded-lg shadow border-grey-150 border px-12 py-8 fadeIn opacity-0">
                <img src={EmptyBowl} />
                <p className="text-base text-center mt-4 text-grey-650">
                  Looks like we couldn’t find the food you were looking for.
                </p>
              </div>
            ) : null}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

const mapState = (state: RootState) => {
  return {
    foodNames: state.foodsNutrientData.foodNames,
    searchValue: state.quickSearchUI.searchValue,
  }
}

export default connect(mapState)(FoodSearchContainer)
