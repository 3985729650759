import React from "react"
import { connect } from "react-redux"
import { RootState } from "../../../state/createStore"
import LinkIcon from "../../../images/svgs/icons/link.svg"
import { metadata } from "../../../data/foodsMetadata.json"
import ExpandedTileDataTable from "./ExpandedTileDataTable"
import parseFoodNameToPath from "../../../utils/parseFoodNameToPath"
import ChevronRight from "../../../images/svgs/buttons/chevronRight.svg"
import { Link } from "gatsby"
import FoodTypePill from "../../CoreUI/FoodTypePill"
import { CSSTransition, SwitchTransition } from "react-transition-group"

const getFoodType = (foodID: string) => {
  let type = null
  metadata.forEach(foodObject => {
    if (foodObject.name === foodID) {
      type = foodObject.type
    }
  })
  return type
}

interface ExpandedFoodTileProps extends ExpandedFoodTileOwnProps {
  foodNutrientData: FoodsNutrientData["Food"]
  userQuotas: UserPersonalQuotas
  nutrientUnits: NutrientUnits
  quotaData: any
}

const ExpandedFoodTile: React.FC<ExpandedFoodTileProps> = ({
  foodID,
  foodNutrientData,
  userQuotas,
  nutrientUnits,
  quotaData,
}) => {
  const microNutrients = Object.entries(quotaData)
    .map(entry => {
        return (entry[1] as {type?: string}).type === "RDA_UL" ? entry[0] : null
    })
    .filter(val => val !== null)

  const foodType = getFoodType(foodID)

  return (
    <div
      className="bg-white rounded-10 shadow border-grey-150 border mx-auto py-6 mb-8 px-8 fadeIn flex-0"
      style={{
        minWidth: 600,
        width: 800,
        maxWidth: 900,
      }}
    >
      <SwitchTransition>
        <CSSTransition
          key={foodID}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="leftInRightOut"
        >
          <div>
            <div className="flex items-center justify-between mb-5">
              <div className="flex items-center">
                <h2 className="text-2xl font-bold text-black mr-4">{foodID}</h2>
                <FoodTypePill foodType={foodType} />
              </div>
              <Link
                className="justify-self-end hover:underline text-havelock flex items-center"
                to={`/encyclopedia/foods/${parseFoodNameToPath(foodID)}`}
              >
                WFN Encyclopedia Entry
                <LinkIcon className="ml-1" style={{ width: 13 }} />
              </Link>
            </div>
            {/* <div className="mt-5 mb-3 flex items-center">
              <MacronutrientIcon className="inline mr-2" />
              <h3 className="text-malachite font-bold inline">
                Micronutrients
              </h3>
            </div> */}
            <div className="flex items-center mb-1">
              <ChevronRight className="mr-3 ml-2 fill-current text-grey-500" />
              <h3 className="font-bold text-black text-lg">RDA/UL Quotas</h3>
            </div>
            <p className="text-base text-grey-650 mb-4">
              Recommended daily allowance (RDA) is the suggested intake of a
              given nutrient per day. Some nutrients also have a daily upper
              limit (UL) which should not be exceeded regularly due to increased
              risk of health complications.
            </p>
            <ExpandedTileDataTable
              foodID={foodID}
              foodNutrientData={foodNutrientData}
              userQuotas={userQuotas}
              shownNutrients={microNutrients}
              nutrientUnits={nutrientUnits}
            />
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

interface ExpandedFoodTileOwnProps {
  foodID: string
}

const mapState = (state: RootState, ownProps: ExpandedFoodTileOwnProps) => {
  const { foodsNutrientData, quotas } = state

  return {
    foodNutrientData:
      foodsNutrientData.portionScaledFoodsNutrientData[ownProps.foodID],
    foodID: ownProps.foodID,
    userQuotas: quotas.userQuotas,
    nutrientUnits: foodsNutrientData.nutrientUnits,
    quotaData: quotas.quotasData,
  }
}

export default connect(mapState)(ExpandedFoodTile)
