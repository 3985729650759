import React, { useState } from "react"
import { connect } from "react-redux"
import { Transition } from "react-transition-group"
import { RootState } from "../../../state/createStore"
import generateQuotaObjects from "../../../utils/quotas/objectGeneration/generateQuotaObjects"
import TileSide, { SideData } from "./TileSide"

const getNewData = (props: TileProps) => {
  const quotaObjects = generateQuotaObjects(
    props.foodNutrientData,
    props.userQuotas
  )
  return {
    rank: props.rank,
    foodName: props.foodName,
    foodType: props.foodType,
    foodNutrientData: props.foodNutrientData,
    tags: props.tags,
    foodQuotaObjects: quotaObjects,
  }
}

const defaultStyle = {
  transition: `transform 400ms, opacity 400ms ease`,
  opacity: 1,
  position: "absolute",
}

const transitionStyles = {
  entering: { transform: "translateX(-10px)", opacity: 0 },
  entered: { transform: "translateX(0px)", opacity: 1 },
  exiting: { transform: "translateX(0px) translateY(-20px)", opacity: 0 },
  exited: { transform: "translateY(-25px)", opacity: 0 },
}

interface TileProps {
  rank: number
  foodName: string
  flipped: boolean
  foodNutrientData: FoodsNutrientData["food"]
  tags: Array<string>
  userQuotas: UserPersonalQuotas
  animationDelay: number
  foodType: string
}

const Tile: React.FC<TileProps> = props => {
  const newData = getNewData(props)
  const [flipped, setFlipped] = useState(false)
  const [rotation, setRotation] = useState(0)
  const [leftData, setLeftData] = useState<SideData>(newData) //Add correct formatting of expected data
  const [rightData, setRightData] = useState<SideData>(newData) //Add correct formatting

  if (props.flipped !== flipped) {
    setRotation(rotation + 180)
    setFlipped(!flipped)
    props.flipped ? setRightData(newData) : setLeftData(newData)
  }

  return (
    <div
      className="mx-2 my-2 w-32 h-32 relative tile-wrapper"
      style={{
        animationDelay: `${props.animationDelay}s`,
        zIndex: 30 - props.rank,
      }}
    >
      <Transition
        in={!flipped}
        timeout={
          !props.flipped ? 400 : (props.animationDelay - 0.05) * 1000 + 400
        }
        unmountOnExit
      >
        {state => (
          <div
            className="relative w-full h-full block"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              transitionDelay: `${props.animationDelay - 0.05}s`,
            }}
          >
            <TileSide
              cachedSideData={leftData}
              rotation={0}
              tabIndex={props.flipped ? -1 : 0}
            />
          </div>
        )}
      </Transition>
      <Transition
        in={flipped}
        timeout={
          props.flipped ? 400 : (props.animationDelay - 0.05) * 1000 + 400
        }
        unmountOnExit
      >
        {state => (
          <div
            className="relative w-full h-full block"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              transitionDelay: `${props.animationDelay - 0.05}s`,
            }}
          >
            <TileSide
              cachedSideData={rightData}
              rotation={0}
              tabIndex={props.flipped ? 0 : -1}
            />
          </div>
        )}
      </Transition>
    </div>
  )
}

interface TileOwnProps {
  index: number
  foodName: string
  flipped: boolean
  animationDelay: number
}

const mapState = (state: RootState, ownProps: TileOwnProps): TileProps => {
  const { searchTags, foodsNutrientData, quotas } = state

  return {
    rank: ownProps.index + 1,
    foodName: ownProps.foodName,
    flipped: ownProps.flipped,
    foodNutrientData:
      foodsNutrientData.portionScaledFoodsNutrientData[ownProps.foodName],
    tags: searchTags,
    userQuotas: quotas.userQuotas,
    animationDelay: ownProps.animationDelay,
    foodType: foodsNutrientData.foodTypes[ownProps.foodName],
  }
}

export default connect<TileProps, {}, TileOwnProps>(mapState)(Tile)
