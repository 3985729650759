const genRandomFoodOrder = (foodNames: FoodNames) => {
  //Fisher-yates shuffle
  let randFoods = [...foodNames]
  let m = randFoods.length
  let t
  let i

  while (m) {
    //Pick random num from 0 -> m
    i = Math.floor(Math.random() * m--)

    //Swapsies
    t = randFoods[m]
    randFoods[m] = randFoods[i]
    randFoods[i] = t
  }

  return randFoods
}
export default genRandomFoodOrder
