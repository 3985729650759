import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import React from "react"
import { connect } from "react-redux"
import foodTypeColorMap from "../../../data/foodTypeColorMap"
import foodTypePluralMap from "../../../data/foodTypesPluralMap"
import SearchIcon from "../../../images/svgs/searchWithBg.svg"
import { RootState } from "../../../state/createStore"
import { openExpandedFoodInfo } from "../../../state/slices/quickSearchUI"
import FoodIcon from "../../shared/FoodIcon"
import ExpandedFoodTile from "./ExpandedFoodTile"

interface ExpandedFoodContainerProps {
  expandedFoodID: string
  foodTypes: { [key: string]: string }
  cachedFoodOrder: Array<string>
  openExpandedFoodInfo: ActionCreatorWithPayload<string, string>
}

const ExpandedFoodContainer: React.FC<ExpandedFoodContainerProps> = ({
  foodTypes,
  expandedFoodID,
  cachedFoodOrder,
  openExpandedFoodInfo,
}) => {
  const foodType = foodTypes[expandedFoodID]
  const sameTypeFoods: Array<string> = []

  Object.keys(foodTypes).forEach(foodName => {
    if (foodTypes[foodName] === foodType) {
      sameTypeFoods.push(foodName)
    }
  })

  return (
    <div className="flex items-start pt-10 justify-center flex-1">
      <div className="ml-12 rounded-t-lg">
        <ExpandedFoodTile foodID={expandedFoodID} />
      </div>
      <div className="flex flex-col">
        <ExpandedFoodSideTile
          cachedFoodOrder={cachedFoodOrder}
          openExpandedFoodInfo={openExpandedFoodInfo}
        />
        <ExpandedFoodSideTile
          foodType={foodType}
          cachedFoodOrder={sameTypeFoods}
          openExpandedFoodInfo={openExpandedFoodInfo}
        />
      </div>
    </div>
  )
}

const mapDispatch = {
  openExpandedFoodInfo,
}

const mapState = (state: RootState) => {
  const foodID = state.quickSearchUI.expandedFoodID
  return {
    expandedFoodID: foodID,
    cachedFoodOrder: state.quickSearchUI.cachedFoodOrder,
    foodTypes: state.foodsNutrientData.foodTypes,
  }
}

export default connect(mapState, mapDispatch)(ExpandedFoodContainer)

interface ExpandedFoodSideTileProps {
  cachedFoodOrder: Array<string>
  openExpandedFoodInfo: ActionCreatorWithPayload<string, string>
  foodType?: string
}

const ExpandedFoodSideTile: React.FC<ExpandedFoodSideTileProps> = ({
  cachedFoodOrder,
  openExpandedFoodInfo,
  foodType,
}) => {
  const slicedFoodOrder = cachedFoodOrder.slice(0, 10)
  return (
    <>
      {cachedFoodOrder.length ? (
        <div className="bg-white rounded-10 shadow border-grey-150 border py-4 ml-4 px-6 fadeInRight flex-shrink-0 mb-4">
          <div className="flex flex-col">
            <div className="flex items-center mb-2">
              {foodType ? (
                <FoodIcon foodType={foodType.toLowerCase()} />
              ) : (
                <SearchIcon />
              )}

              <h3 className="font-bold text-md ml-3">
                {foodType ? (
                  <span>
                    More{" "}
                    <span style={{ color: foodTypeColorMap[foodType].text }}>
                      {foodTypePluralMap[foodType.toLowerCase()]
                        ? foodTypePluralMap[foodType.toLowerCase()]
                        : foodType + "s"}
                    </span>
                  </span>
                ) : (
                  `More from search`
                )}
              </h3>
            </div>
            <ul>
              {slicedFoodOrder.map(foodName => (
                <li
                  key={`${foodName}-sidetile-li`}
                  className="text-base cursor-pointer"
                  onClick={() => openExpandedFoodInfo(foodName)}
                >
                  {foodName}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  )
}
