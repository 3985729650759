import React, { useState } from "react"
import { removeSearchTag } from "../../state/slices/searchTags"
import { connect } from "react-redux"
import { RootState } from "../../state/createStore"
import CrossIcon from "../../images/svgs/buttons/cross.svg"
import { Flipper, Flipped } from "react-flip-toolkit"

interface TagBarProps {
  tags: Array<string>
  removeSearchTag: typeof removeSearchTag
}

const TagBar: React.FC<TagBarProps> = ({ tags, removeSearchTag }) => {
  const [removingTag, setRemovingTag] = useState<string | null>(null)

  const onAppear = (el: HTMLElement) => {
    el.classList.add("fadeIn")
    setTimeout(() => {
      el.style.opacity = String(1)
      el.classList.remove("fadeIn")
    }, 500)
  }

  const requestRemoveTag = (tag: string) => {
    setRemovingTag(tag)
    const onFinish = () => {
      removeSearchTag(tag)
      setRemovingTag(null)
    }
    setTimeout(() => onFinish(), 50)
  }

  return (
    <div className="flex items-center">
      <label className="block mb-1 text-grey-700 font-bold px-6 text-base">
        Searched
      </label>
      <div className="h-5 border-r border-grey-150 mr-6" />
      <div className="flex h-10 items-center">
        {!tags.length ? (
          <div className="rounded-8 bg-grey-100 border-grey-150 h-36 flex items-center justify-center w-40 text-sm text-grey-400 fadeIn">
            NONE
          </div>
        ) : null}
        <Flipper flipKey={tags.join("")} className="flex">
          {tags.map(tag => (
            <Flipped key={tag} flipId={tag} onAppear={onAppear}>
              <div
                className={`flex bg-feta-bg rounded-8 h-36 items-center w-40 text-sm text-feta-text mr-2 px-1 pr-3 hover:bg-conifer-light ${
                  removingTag === tag ? "fadeOut" : ``
                }`}
                key={tag}
              >
                <button
                  className="p-2 focus-visible"
                  onClick={() => requestRemoveTag(tag)}
                >
                  <CrossIcon />
                </button>
                <label className="text-center flex-1">
                  {tag.toUpperCase()}
                </label>
              </div>
            </Flipped>
          ))}
        </Flipper>
      </div>
    </div>
  )
}

const mapDispatch = { removeSearchTag }

const mapState = (state: RootState) => {
  return {
    tags: state.searchTags,
  }
}

export default connect(mapState, mapDispatch)(TagBar)
