import { useEffect } from "react"
import Modal from "react-modal"

type ModalRef =
  | string
  | React.RefObject<Modal>
  | ((instance: Modal | null) => void)
  | null
  | undefined

const useClickOutsideModal = (
  onClickOutside: () => void,
  modalRef: ModalRef,
  buttonNode: HTMLElement | null
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (
        (modalRef as React.RefObject<Modal>).current &&
        !(modalRef as React.RefObject<Modal>).current!.node.contains(event.target as Node | null) &&
        buttonNode &&
        !buttonNode.contains(event.target as Node | null)
      ) {
        onClickOutside()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [modalRef, buttonNode, onClickOutside])
}
export default useClickOutsideModal
