import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import React, { useReducer } from "react"
import NumericInput from "./NumericInput"
import SelectorInput from "./SelectorInput"

type Sex = "male" | "female"

type Action =
  | { type: "height"; height: number }
  | { type: "weight"; weight: number }
  | { type: "age"; age: number }
  | { type: "sex"; sex: Sex }

function metricsReducer(oldState: Metrics, action: Action) {
  const state = { ...oldState }
  switch (action.type) {
    case "height":
      state.height = action.height
      break
    case "weight":
      state.weight = action.weight
      break
    case "age":
      state.age = action.age
      break
    case "sex":
      state.sex = action.sex
      break
    default:
      throw new Error()
  }
  return state
}

interface MetricsFormProps {
  updateMetrics: ActionCreatorWithPayload<Metrics, string>
  initialMetrics: Metrics
}

const MetricsForm: React.FC<MetricsFormProps> = ({
  initialMetrics,
  updateMetrics,
}) => {
  if (typeof window !== `undefined`) {
    const [metrics, dispatch] = useReducer(metricsReducer, initialMetrics)

    const handleMetricSubmit = () => {
      updateMetrics(metrics)
    }

    return (
      <div className="relative">
        <NumericInput
          onSubmit={height => dispatch({ type: "height", height })}
          label="Height"
          sublabel="(Cm)"
          initialValue={metrics?.height}
          widthFull={true}
        />
        <div className="h-3" />
        <NumericInput
          onSubmit={weight => dispatch({ type: "weight", weight })}
          label="Weight"
          sublabel="(Kg)"
          initialValue={metrics.weight}
          widthFull={true}
        />
        <div className="h-3" />
        <NumericInput
          onSubmit={age => dispatch({ type: "age", age })}
          label="Age"
          sublabel="(Years)"
          initialValue={metrics.age}
          widthFull={true}
        />
        <div className="h-3" />
        <SelectorInput
          options={[
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
          ]}
          initialValue="male"
          onSubmit={sex => dispatch({ type: "sex", sex: sex as Sex })}
          label="Sex"
          sublabel="(m/f)"
          widthFull={true}
        />
        <div className="h-5" />
        <button
          className="w-full bg-grey-600 text-center rounded-md p-1 py-2 focus-visible hover:bg-grey-650 text-white transition duration-300 ease-in-out"
          onClick={() => handleMetricSubmit()}
        >
          CONFIRM
        </button>
      </div>
    )
  }
  return null
}
export default MetricsForm
