import React, { useState } from "react"
import generateQuotaObjects from "../../../utils/quotas/objectGeneration/generateQuotaObjects"
import AscDescButtons from "../../CoreUI/AscDescButtons"
import { PercentPill } from "../../PercentPill"

const TABLE_HEADERS = ["Nutrient", "Quantity", "RDA", "UL", "RDA %", "UL %"]

interface SortBy {
  key: "rdaPercent" | "ulPercent"
  order: "desc" | "asc"
}

interface ExpandedTileDataTableProps extends ExpandedTileDataTableOwnProps {
  shownNutrients: Array<string>
  foodNutrientData: FoodsNutrientData["Food"]
  userQuotas: UserPersonalQuotas
  nutrientUnits: NutrientUnits
}

const ExpandedTileDataTable: React.FC<ExpandedTileDataTableProps> = ({
  shownNutrients,
  foodNutrientData,
  userQuotas,
  nutrientUnits,
}) => {
  const [maxNutrientsShown, setMaxNutrientShown] = useState(8)
  const quotaObjects = generateQuotaObjects(foodNutrientData, userQuotas)
  const [expanded, setExpanded] = useState(false)
  const [sortBy, setSortBy] = useState<SortBy>({
    key: "rdaPercent",
    order: "desc",
  })

  let sortedNutrients = shownNutrients

  if (sortBy.key === "rdaPercent" || sortBy.key === "ulPercent") {
    sortedNutrients = shownNutrients.sort((nutrientA, nutrientB) => {
      return sortBy.order === "desc"
        ? quotaObjects[nutrientB]?.typeValues[sortBy.key] -
            quotaObjects[nutrientA]?.typeValues[sortBy.key]
        : quotaObjects[nutrientA]?.typeValues[sortBy.key] -
            quotaObjects[nutrientB]?.typeValues[sortBy.key]
    })
  }

  const handleSortByChange = (key: SortBy["key"], order: SortBy["order"]) => {
    setSortBy({ key, order })
  }

  const handleExpandTable = () => {
    setMaxNutrientShown(shownNutrients.length)
    setExpanded(true)
  }

  const handleCollapseTable = () => {
    setMaxNutrientShown(8)
    setExpanded(false)
  }

  const clippedNutrients = sortedNutrients.slice(0, maxNutrientsShown)

  return (
    <>
      <table className="w-full">
        <thead className="border-b border-grey-150">
          <tr>
            {TABLE_HEADERS.map((header, idx) => (
              <th
                className={`font-normal text-grey-400 pb-1 text-left ${
                  idx === 0 ? "" : ""
                }`}
                key={header}
              >
                {header.toUpperCase()}
                {header === "RDA %" ? (
                  <AscDescButtons
                    onDownPress={() => handleSortByChange("rdaPercent", "desc")}
                    onUpPress={() => handleSortByChange("rdaPercent", "asc")}
                    active={
                      sortBy.key === "rdaPercent"
                        ? sortBy.order === "asc"
                          ? "up"
                          : "down"
                        : null
                    }
                  />
                ) : null}
                {header === "UL %" ? (
                  <AscDescButtons
                    onDownPress={() => handleSortByChange("ulPercent", "desc")}
                    onUpPress={() => handleSortByChange("ulPercent", "asc")}
                    active={
                      sortBy.key === "ulPercent"
                        ? sortBy.order === "asc"
                          ? "up"
                          : "down"
                        : null
                    }
                  />
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {clippedNutrients.map((nutrient, idx) => (
            <tr
              className={`my-2 hover:bg-grey-75 bg-white text-grey-650 transition duration-300 border-b h-10 ${
                idx === clippedNutrients.length - 1
                  ? ` border-grey-150`
                  : ` border-grey-100`
              }`}
              key={nutrient}
            >
              <td className="h-8" style={{ width: "16.666%" }}>
                {nutrient}
              </td>
              <td className="" style={{ width: "16.666%" }}>
                {foodNutrientData[nutrient]}
                {nutrientUnits[nutrient]}
              </td>
              <td className="" style={{ width: "16.666%" }}>
                {(userQuotas[nutrient] as RDAULQuota).rda}
                {userQuotas[nutrient].units}
              </td>
              <td className="" style={{ width: "16.666%" }}>
                {(userQuotas[nutrient] as RDAULQuota).ul
                  ? `${(userQuotas[nutrient] as RDAULQuota).ul} ${userQuotas[nutrient].units}`
                  : ""}
              </td>
              <td className="" style={{ width: "16.666%" }}>
                <PercentPill
                  percentValue={(quotaObjects[nutrient]?.typeValues as RDAULQuotaTypeValues).rdaPercent ?? 0}
                  warning={false}
                />
              </td>
              <td className="" style={{ width: "16.666%" }}>
                {(quotaObjects[nutrient]?.typeValues as RDAULQuotaTypeValues).ulPercent ? (
                  <PercentPill
                    percentValue={(quotaObjects[nutrient]?.typeValues as RDAULQuotaTypeValues).ulPercent ?? 0}
                    warning={true}
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="py-2 block relative">
        <button
          className="block text-sushi-dark mx-auto focus-visible hover:underline"
          onClick={() =>
            expanded ? handleCollapseTable() : handleExpandTable()
          }
        >
          {expanded ? `Show Less` : `Show All`}
        </button>
      </div>
    </>
  )
}

interface ExpandedTileDataTableOwnProps {
  foodID: string
}

export default ExpandedTileDataTable
