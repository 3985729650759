import { TileContainerProps } from "../../components/QuickSearch/SmallFoodTile/TileContainer"

const calcFoodOrder = (props: TileContainerProps) => {
  const {
    foodsNutrientData,
    nutrientDataRanges,
    nutrientDataMeans,
    foodNames,
    searchTags,
  } = props
  let foodNamesOrder = [...foodNames]

  foodNamesOrder.sort((a, b) => {
    let a_total = 0,
      b_total = 0
    //Normalise each nutrient selected then add to total
    //Weights order of tags by -0.05%
    for (let i = 0; i < searchTags.length; i++) {
      a_total += diff(
        foodsNutrientData[a][searchTags[i]],
        nutrientDataMeans[searchTags[i]],
        nutrientDataRanges[searchTags[i]],
        i
      )
      b_total += diff(
        foodsNutrientData[b][searchTags[i]],
        nutrientDataMeans[searchTags[i]],
        nutrientDataRanges[searchTags[i]],
        i
      )
    }
    return b_total - a_total
  })
  return foodNamesOrder
}
export default calcFoodOrder

const diff = (a: number, b: number, c: number, d: number) => {
  return ((a - b) / c) * (1 - 0.05 * d)
}
