import React from "react"
import ArrowUp from "../../images/svgs/buttons/smallUpArrow.svg"
import ArrowDown from "../../images/svgs/buttons/smallDownArrow.svg"

interface AscDescButtonsProps {
  onUpPress: () => void
  onDownPress: () => void
  active: "up" | "down" | null
}

const AscDescButtons: React.FC<AscDescButtonsProps> = ({
  onUpPress,
  onDownPress,
  active,
}) => {
  return (
    <div className="inline-flex mx-3">
      <button
        className={`bg-grey-100 rounded-lg w-4 flex items-center justify-center h-4 mr-1 focus-visible group ${
          active === "up" ? `` : ``
        }`}
        onClick={() => onUpPress()}
      >
        <ArrowUp
          className={`stroke-current ${
            active === "up"
              ? `text-grey-650`
              : `text-grey-400 group-hover:text-grey-500`
          }`}
        />
      </button>
      <button
        className="bg-grey-100 rounded-lg w-4 flex items-center justify-center h-4 focus-visible group"
        onClick={() => onDownPress()}
      >
        <ArrowDown
          className={`stroke-current ${
            active === "down"
              ? `text-grey-650`
              : `text-grey-400 group-hover:text-grey-500`
          }`}
        />
      </button>
    </div>
  )
}
export default AscDescButtons
