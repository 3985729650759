export const flipAllTiles = (flippedTiles: Array<boolean>) => {
  return flippedTiles.map(val => !val)
}

export const flipSingleTile = (
  flippedTiles: Array<boolean>,
  tileIndex: number
) => {
  return flippedTiles.map((val, index) => {
    if (index === tileIndex) {
      return !val
    }
    return val
  })
}
