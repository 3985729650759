import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload
} from "@reduxjs/toolkit"
import React, { useCallback } from "react"
import { connect } from "react-redux"
import NutrientsInfo from "../../data/nutrientsInfo.json"
import { RootState } from "../../state/createStore"
import { updatePortion } from "../../state/slices/foodsNutrientData"
import {
    openUtilityModal, setSearchValue, toggleSearchMode
} from "../../state/slices/quickSearchUI"
import { updateMetrics } from "../../state/slices/quotas"
import { addSearchTag } from "../../state/slices/searchTags"
import MetricsForm from "../CoreUI/MetricsForm"
import NumericInput from "../CoreUI/NumericInput"
import SearchBar from "../CoreUI/SearchBar"
import Toggle from "../CoreUI/Toggle"
import Tooltip from "../CoreUI/Tooltip"

interface SettingsProps {
  metrics: Metrics
  addSearchTag: ActionCreatorWithPayload<string, string>
  updatePortion: ActionCreatorWithPayload<number, string>
  updateMetrics: ActionCreatorWithPayload<Metrics, string>
  nutrientNames: NutrientNames
  expandedFoodID: string
  portion: number
  tags: Array<string>
  utilityModalOpen: boolean
  toggleSearchMode: ActionCreatorWithoutPayload<string>
  searchMode: "nutrient" | "food"
  setSearchValue: ActionCreatorWithPayload<string, string>
}

const Settings: React.FC<SettingsProps> = ({
  addSearchTag,
  updateMetrics,
  updatePortion,
  nutrientNames,
  tags,
  metrics,
  portion,
  toggleSearchMode,
  searchMode,
  setSearchValue,
}) => {
  const handleAddTag = (tag: string) => {
    addSearchTag(tag)
  }

  const debounced = useCallback((newPortion: number) => {
    updatePortion(newPortion)
  }, [])
  const searchValueDebounced = useCallback((newSearchVal: string) => {
    setSearchValue(newSearchVal)
  }, [])

  const handleToggle = () => {
    toggleSearchMode()
    // setSearchValue("")
  }

  const searchSuggestions = generateSearchSuggestions(nutrientNames, tags)

  return (
    <div className="flex relative flex-col items-stretch w-full">
      <div className="flex font-bold py-3 items-center text-grey-700">
        <div className="bg-sushi-light w-1 h-4 mr-3" />
        SEARCH
      </div>
      <div className="relative mb-3">
        <Toggle
          offLabel="Nutrient"
          onLabel="Food"
          onToggle={() => handleToggle()}
          initialState={searchMode === "food"}
        />
        <SearchBar
          placeholder={
            searchMode === "nutrient"
              ? tags.length < 4
                ? `Find a nutrient`
                : `Max searched tags`
              : `Find a food`
          }
          onSubmit={searchMode === "nutrient" ? handleAddTag : () => {}}
          suggestions={searchMode === "nutrient" ? searchSuggestions : []}
          disabled={tags.length >= 4}
          onChange={newValue => searchValueDebounced(newValue)}
        />
      </div>
      <NumericInput
        step={25}
        onSubmit={(val: number) => debounced(val)}
        label="Portion"
        sublabel="(Grams)"
        initialValue={portion}
        max={1000}
        widthFull={true}
      />
      <div className="flex items-center mt-8">
        <div className="bg-sushi-light w-1 h-4 mr-3" />
        <span className="font-bold text-grey-700">METRICS</span>
        <Tooltip
          content={
            <>
              <h2 className="font-bold mb-1 text-white">Privacy Notice: </h2>
              <p>
                None of your personal metrics are sent or stored beyond your
                browser
              </p>
            </>
          }
          maxWidth={200}
        >
          <div
            className="rounded-full h-4 w-4 bg-grey-600 text-white text-center select-none ml-3 text-smm hover:bg-grey-650 cursor-pointer"
            style={{ paddingBottom: 2, fontSize: 12 }}
          >
            i
          </div>
        </Tooltip>
      </div>
      <p className="text-grey-500 text-sm mt-1 mb-4">
        For quota percentages that reflect your personal requirements, input
        your biometrics below
      </p>
      <MetricsForm initialMetrics={metrics} updateMetrics={updateMetrics} />
    </div>
  )
}

const mapState = (state: RootState) => {
  return {
    utilityModalOpen: state.quickSearchUI.utilityModalOpen,
    nutrientNames: state.foodsNutrientData.nutrientNames,
    expandedFoodID: state.quickSearchUI.expandedFoodID,
    portion: state.foodsNutrientData.portion,
    metrics: state.quotas.metrics,
    tags: state.searchTags,
    searchMode: state.quickSearchUI.searchMode,
  }
}

const mapDispatch = {
  toggleSearchMode,
  addSearchTag,
  updatePortion,
  updateMetrics,
  openUtilityModal,
  setSearchValue,
}

export default connect(mapState, mapDispatch)(Settings)

const generateSearchSuggestions = (
  nutrientNames: NutrientNames,
  tags: Array<string>
) => {
  return nutrientNames
    .map((nutrientName, idx) => {
      return {
        id: idx,
        name: nutrientName,
        icon: NutrientsInfo[nutrientName]?.category,
      }
    })
    .filter(suggestion => {
      return !tags.includes(suggestion.name)
    })
}
