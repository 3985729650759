import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import useKeyPress from "../../utils/hooks/useKeyPress"
import SearchIcon from "../../images/svgs/icons/search.svg"
import { Transition } from "react-transition-group"
import DropAndScale from "../Transitions/DropAndScale"

interface SearchBarProps {
  placeholder: string
  onSubmit: (value: string) => void
  suggestions: Array<itemType>
  disabled?: boolean
  onChange?: (value: string) => void
}

type itemType = { id: number; name: string; icon?: string }

type ListItemProps = {
  item: itemType
  active: boolean
  setSelected: Dispatch<SetStateAction<SetStateAction<itemType | undefined>>>
  setHovered: Dispatch<SetStateAction<itemType | undefined>>
}

const ListItem: React.FC<ListItemProps> = ({
  item,
  active,
  setSelected,
  setHovered,
}) => {
  return (
    <li
      className={`py-2 rounded-sm px-4 cursor-pointer flex items-center ${
        active ? "bg-feta-bg text-feta-rich" : " text-grey-700"
      }`}
      onMouseDown={() => setSelected(item)}
      onMouseEnter={() => setHovered(item)}
      onMouseLeave={() => setHovered(undefined)}
    >
      {item.name}
    </li>
  )
}

const matchSearchTerm = (list: Array<itemType>, searchTerm: string) => {
  return list.filter(val =>
    val.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  onSubmit,
  suggestions,
  disabled,
  onChange,
}) => {
  const searchInput = useRef(null)
  const downPress = useKeyPress({
    targetKey: "ArrowDown",
    ref: searchInput,
    preventDefault: true,
  })
  const upPress = useKeyPress({
    targetKey: "ArrowUp",
    ref: searchInput,
    preventDefault: true,
  })
  const enterPress = useKeyPress({ targetKey: "Enter", ref: searchInput })
  const [cursor, setCursor] = useState<number>(0)
  const [hovered, setHovered] = useState<itemType | undefined>(undefined)
  const [searchValue, setSearchValue] = useState("")
  const [focused, setFocused] = useState(false)
  const [selected, setSelected] = useState<
    React.SetStateAction<itemType | undefined>
  >(undefined)
  const [items, setItems] = useState(matchSearchTerm(suggestions, searchValue))

  const handleSubmit = (key: string) => {
    onSubmit(key)
    // setCursor(0)
    setHovered(undefined)
    setSelected(undefined)
    setSearchValue("")
    if (onChange) onChange("")
    // setItems(matchSearchTerm(suggestions, ""))
  }

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    // Cache suggestions for transition when input empty
    if (e.currentTarget.value !== "") {
      setItems(matchSearchTerm(suggestions, e.currentTarget.value))
      setCursor(0)
      setSelected(undefined)
    }

    if (onChange) onChange(e.currentTarget.value)
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    // setItems(matchSearchTerm(suggestions, searchValue))
    // setCursor(0)
  }, [suggestions])

  useEffect(() => {
    if (selected) {
      handleSubmit(selected.name)
    }
  }, [selected])

  useEffect(() => {
    if (items.length && downPress) {
      setCursor(prevState => (prevState < items.length - 1 ? prevState + 1 : 0))
    }
  }, [downPress])

  useEffect(() => {
    if (items.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : items.length - 1))
    }
  }, [upPress])

  useEffect(() => {
    if ((items.length && enterPress) || (items.length && hovered)) {
      handleSubmit(items[cursor].name)
    }
  }, [enterPress])

  useEffect(() => {
    if (items.length && hovered) {
      setCursor(
        items
          .map(e => {
            return e.id
          })
          .indexOf(hovered.id)
      )
    }
  }, [hovered])

  const showSuggestions = Boolean(searchValue && focused && items.length)

  return (
    <div className="relative">
      <div className="absolute flex items-center pl-3 h-full">
        <SearchIcon style={{ height: 15 }} />
      </div>
      <input
        ref={searchInput}
        onChange={handleChange}
        value={searchValue}
        className={`transition-bg duration-150 ease-in-out text-sm border placeholder-grey-400 w-full h-10 pl-10 text-grey-700 outline-none input-focus ${
          showSuggestions ? `rounded-md` : `rounded-md`
        }  ${
          focused ? `bg-grey-100 border-gossip` : `bg-grey-100 border-grey-150`
        }`}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        disabled={disabled}
      />
      <DropAndScale
        transitionIn={showSuggestions}
        styles={{ maxHeight: 300 }}
        className="absolute mt-2 max-h-32 w-full z-50 bg-grey-75 border border-grey-150 rounded-md custom-shadow overflow-hidden"
      >
        <ul role="listbox">
          {items.map((item, i) => (
            <ListItem
              key={item.id}
              active={i === cursor}
              item={item}
              setSelected={setSelected}
              setHovered={setHovered}
            />
          ))}
        </ul>
      </DropAndScale>
    </div>
  )
}
export default SearchBar
