import React from "react"
import { connect } from "react-redux"
import { Transition } from "react-transition-group"
import BackArrow from "../../images/svgs/buttons/backArrow.svg"
import { RootState } from "../../state/createStore"
import { Modes } from "./ContentsController"
import TagBar from "./TagBar"

const tagBarDefaultStyle = {
  transition: `transform 200ms ease-out, opacity 200ms ease-out`,
  opacity: 1,
}

const tagBarTransitionStyles = {
  entering: { transform: "translateY(-7px)", opacity: 0 },
  entered: { transform: "translateY(0px)", opacity: 1 },
  exiting: { transform: "translateY(-7px)", opacity: 0 },
  exited: { transform: "translateY(-7px)", opacity: 0 },
}

const backButtonDefaultStyle = {
  transition: `transform 200ms ease-out, opacity 200ms ease-out`,
  opacity: 1,
}

const backButtonTransitionStyles = {
  entering: { transform: "translateY(7px)", opacity: 0 },
  entered: { transform: "translateY(0px)", opacity: 1 },
  exiting: { transform: "translateY(7px)", opacity: 0 },
  exited: { transform: "translateY(7px)", opacity: 0 },
}

const matchSearchTerm = (list: Array<string>, searchTerm: string) => {
  if (searchTerm === "") {
    return list
  }
  return list.filter(val =>
    val.toLowerCase().includes(searchTerm.toLowerCase())
  )
}

interface ContentTopBarProps {
  expandedFoodID: boolean
  contentMode: Modes
  closeExpandedFoodInfo: () => void
  foodNames: Array<string>
  searchValue: string
}

const ContentTopBar: React.FC<ContentTopBarProps> = ({
  contentMode,
  closeExpandedFoodInfo,
  foodNames,
  searchValue,
}) => {
  return (
    <div className="relative bg-white border-grey-150 border-b flex items-center py-2 shadow-sm h-16 overflow-x-auto w-screen sm:w-full">
      <Transition in={contentMode === "food"} timeout={200} unmountOnExit>
        {state => (
          <div
            className="absolute"
            style={{
              ...tagBarDefaultStyle,
              ...tagBarTransitionStyles[state],
            }}
          >
            <h3 className="pl-6 font-bold text-md text-grey-700">
              <span className="text-grey-400 mr-1">
                {matchSearchTerm(foodNames, searchValue).length}
              </span>{" "}
              Search Results
            </h3>
          </div>
        )}
      </Transition>
      <Transition in={contentMode === "nutrient"} timeout={200} unmountOnExit>
        {state => (
          <div
            className="absolute"
            style={{
              ...tagBarDefaultStyle,
              ...tagBarTransitionStyles[state],
            }}
          >
            <TagBar />
          </div>
        )}
      </Transition>
      <Transition
        in={contentMode === "expandedFood"}
        timeout={200}
        unmountOnExit
      >
        {state => (
          <div
            className="absolute pl-6"
            style={{
              ...backButtonDefaultStyle,
              ...backButtonTransitionStyles[state],
            }}
          >
            <button
              onClick={() => closeExpandedFoodInfo()}
              className="flex items-center hover:underline group focus-visible text-base text-grey-700 font-bold"
            >
              <BackArrow className="inline mr-3 stroke-current text-grey-400 group-hover:text-grey-600 p-0 flex-0 transition duration-200 h-3" />
              Back to search
            </button>
          </div>
        )}
      </Transition>
    </div>
  )
}

const mapState = (state: RootState) => {
  return {
    foodNames: state.foodsNutrientData.foodNames,
    searchValue: state.quickSearchUI.searchValue,
  }
}

export default connect(mapState)(ContentTopBar)
