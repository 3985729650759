import filterNutrientNames from "./filterNutrientNames"

const calcTopNutrients = (foodQuotaObjects: any, tags: Array<string>) => {
  const nutrientNames: Array<string> = Object.keys(foodQuotaObjects)
  let topNutrients: Array<string> = filterNutrientNames(nutrientNames, tags)
  let nullQuotas = nutrientNames.filter(
    nutrientName => foodQuotaObjects[nutrientName] === null
  )
  topNutrients = filterNutrientNames(topNutrients, nullQuotas)
  topNutrients.sort((a, b) => {
    if (foodQuotaObjects[a].warning && !foodQuotaObjects[b].warning) {
      return -1
    } else if (!foodQuotaObjects[a].warning && foodQuotaObjects[b].warning) {
      return 1
    }
    return (
      parseInt(foodQuotaObjects[b].displayValue) -
      parseInt(foodQuotaObjects[a].displayValue)
    )
  })
  return topNutrients
}
export default calcTopNutrients
